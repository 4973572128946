@import 'theme/variables';

.only-form-page-wrapper {
  .logo-container {
    margin-bottom: $only-form-page-wrapper-margin-bottom;

    &.for-login {
      margin-bottom: $only-form-login-page-wrapper-margin-bottom;
    }
  }

  .graphics-container {
    margin-bottom: $graphics-container-margin-bottom;
  }
}
