@import 'theme/variables';
@import 'theme/themify';

:local{

    .inner {
        gap: 20px;

        a{
            padding: 10px 20px;
            border-radius: 20px;
            color: white !important; //important because app wrapper component has link color already defined
            @include themify($themes) {
                background: themed('button-primary-bg-color');
            }
            text-decoration: none;
            font-weight: bold;
            cursor: pointer;
            transition: 0.4s;

            &:hover {
                opacity: 0.8;
            }


        }

    }
}
