@import 'theme/variables';
@import 'theme/themify';

:local {

    .container {
        padding: 0rem 1rem;
        display: flex;

        .cardGrid {
            display: flex;
            gap: 1rem 4rem;
            flex-wrap: wrap;

            .card {
                text-decoration: none;
                border-radius: 1.5rem;
                overflow: hidden;
                width: 16rem;
                height: 18rem;
                background: linear-gradient(145deg, #20222d, #181a23);
                box-shadow: 0 8px 15px rgba(0, 0, 0, 0.25), 0 -2px 4px rgba(255, 255, 255, 0.1);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transition: transform 0.3s, box-shadow 0.3s;

                &:hover {
                    transform: scale(1.05);
                    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.3), 0 -3px 6px rgba(255, 255, 255, 0.15);
                }

                .cardImage {
                    width: 60%;
                    height: auto;
                    margin-bottom: 1rem;
                    filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.5));
                }

                .cardTitle {
                    color: white;
                    font-size: 1.25rem;
                    font-weight: bold;
                    text-align: center;
                    margin: 0;
                    padding: 0.1rem 0;

                    // background: crimson;
                    @include themify($themes) {
                        background: themed('button-primary-bg-color');
                    }

                    border-radius: 0.5rem;
                    box-shadow: inset 0 -1px 3px rgba(0, 0, 0, 0.4);
                    width: 90%;
                }
            }
        }
    }

}