@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';
@import 'theme/variables';

.learn-more-link {
  @include media-breakpoint-up(md) {
    margin-top: $coupon-action-section-margin-top;
  }
  @include media-breakpoint-down(md) {
    margin-bottom: $coupon-action-section-margin-top;
  }

  font-size: $membership-info-learn-more-link-font-size;
}
