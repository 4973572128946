@import 'theme/themify';
@import 'theme/variables';

:global {
  .btn {
    &:disabled {
      opacity: $disabled-opacity;
    }
  }
}

:local {
  .btn-primary {
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-base;
    line-height: $btn-primary-line-height;
    border-radius: $btn-primary-border-radius;
    padding: $btn-primary-padding-x $btn-primary-padding-y;
    min-width: $btn-primary-min-width;

    &:focus {
      outline: 0;

      @include themify($themes) {
        box-shadow: 0 0 0 $box-shadow-spread-radius-base
          themed('button-primary-focus-shadow-color') !important;
      }
    }

    @include themify($themes) {
      background: themed('button-primary-bg-color');
      color: themed('button-primary-text-color');
      box-shadow: 0 $btn-primary-box-shadow-offset-y
        $btn-primary-box-shadow-blur-radius
        themed('button-primary-shadow-color');
    }
  }

  .with-gap {
    margin: $button-margin;
  }

  .checkable {
    font-weight: $font-weight-normal;
    min-width: unset;
    margin: $checkable-margin-x $checkable-margin-y;

    &:not(.checked) {
      @include themify($themes) {
        background: themed('button-primary-selectable-background-color');
        border-color: themed('button-primary-selectable-border-color');
        color: themed('button-primary-selectable-text-color');
      }
    }
  }

  .btn-outline {
    @include themify($themes) {
      box-shadow: inset #d9d9d9 0 0 20px -2px;
      background: white;
      color: themed('button-outline-text-color');
      border: $border-width-base solid themed('button-outline-border-color');
    }

    &:focus {
      @include themify($themes) {
        box-shadow: 0 0 0 $box-shadow-spread-radius-base
          themed('button-outline-focus-shadow-color') !important;
      }
    }
  }

  .btn-lg {
    min-width: $btn-lg-min-width;
    padding: $btn-lg-padding-x $btn-lg-padding-y;
    font-size: $font-size-md;
    line-height: $btn-lg-line-height;
  }
}
