@import 'theme/variables';

.landing-block {
  margin-top: $landing-block-margin-top;

  :global {
    h5 {
      line-height: unset;
    }

    ol {
      min-height: $landing-block-list-min-height;

      li {
        margin: $form-element-container-margin-bottom 0;
      }
    }
  }
}
