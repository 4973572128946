@import 'theme/themify';
@import 'theme/variables';

:local {
  .membership-plans-description {
    font-size: $font-size-base;
  }

  .membership-plans-section {
    margin-top: $membership-plans-section-margin-top;

    .membership-plan-block {
      border-radius: $block-border-radius;
      text-align: center;
      @include themify($themes) {
        background-color: themed('membership-plan-block-background-color');
      }
      @include themify($themes) {
        box-shadow: 0 $box-shadow-offset-base themed('block-shadow-color');
      }

      .free-plan {
        @include themify($themes) {
          background: themed(
            'free-membership-plan-background-color'
          ) !important;
        }
      }
      .standard-plan {
        @include themify($themes) {
          background: themed(
            'standard-membership-plan-background-color'
          ) !important;
        }
      }
      .premium-plan {
        @include themify($themes) {
          background: themed(
            'premium-membership-plan-background-color'
          ) !important;
        }
      }

      .membership-plan-title-section {
        padding: $membership-plan-title-section-padding;
        border-radius: $membership-plan-title-section-border-radius;

        @include themify($themes) {
          background: themed(
            'membership-plan-title-section-default-background-color'
          );
          color: themed('membership-plan-title-section-text-color');
        }
      }

      .membership-plan-content-section {
        padding: $membership-plan-content-section-padding;
        margin-bottom: $membership-plan-content-section-margin-bottom;

        .plan-price {
          font-size: $membership-plan-price-font-size;
          font-weight: normal;
        }

        .plan-benefits-separator {
          margin-top: $membership-plan-benefits-separator-margin-top;
          margin-bottom: $membership-plan-benefits-separator-margin-bottom;
          line-height: $membership-plan-benefits-separator;
          font-weight: bold;
          font-size: $membership-plan-benefits-separator-font-size;
          @include themify($themes) {
            color: themed('membership-plan-benefits-separator-text-color');
          }
        }
      }

      .choose-plan-btn-container {
        margin-bottom: $choose-membership-plan-btn-container-margin-bottom;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
      }

      .non-clickable-plan-btn {
        cursor: default;
        pointer-events: none;
      }
    }
  }
}
