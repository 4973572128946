@import 'theme/themify';
@import 'theme/variables';

:local {
  .custom-notification-badge {
    font-size: $custom-notification-badge-font-size;
    line-height: $custom-notification-badge-line-height;
    @include themify($themes) {
      background-color: themed('custom-notification-badge-background-color');
      color: themed('custom-notification-badge-color');
    }
  }
}
