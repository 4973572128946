@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';
@import 'theme/themify';
@import 'theme/variables';

:local {
  .coupon-action-section {
    font-size: $coupon-action-section-font-size;
    margin-bottom: $coupon-action-section-margin-bottom;
    display: inline-block;
    cursor: pointer;
    @include themify($themes) {
      color: themed(muted-text-color);
    }

    .coupon-action-btn {
      border-radius: $border-radius-circle;
    }

    &:hover .coupon-action-btn {
      @include themify($themes) {
        box-shadow: $coupon-action-btn-box-shadow-offset
          themed('coupon-action-btn-box-shadow-color');
      }
    }

    &.disabled {
      cursor: default;
      pointer-events: none;

      &:hover .coupon-action-btn {
        box-shadow: none;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .coupon-action-section {
      flex-basis: $coupon-action-section-flex-basis;
      margin-bottom: 0;
      margin-top: $coupon-action-section-margin-top;
    }
  }
}
