@import 'theme/themify';
@import 'theme/variables';

:local {
  .form-field-label {
    margin-bottom: $form-field-label-margin-bottom;
    font-size: $form-field-label-font-size;
    line-height: $form-field-label-line-height;
    text-transform: uppercase;

    @include themify($themes) {
      color: themed('form-field-label-text-color');
    }

    &.is-invalid {
      font-weight: $font-weight-bold;

      @include themify($themes) {
        color: themed('invalid-feedback-text-color');
      }
    }
  }
}
