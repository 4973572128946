@import 'theme/themify';
@import 'theme/variables';

:local {
  .radio {
    position: relative;
    margin-bottom: $radio-margin-bottom;
    margin-right: $radio-margin-right;

    input {
      cursor: pointer;
      opacity: 0;
      width: $radio-input-height;
      height: $radio-input-height;
      z-index: 2;
    }
    label {
      opacity: $radio-label-opacity;
      margin-left: $radio-label-margin-left;
      margin-bottom: $radio-label-margin-bottom;
    }

    &::before {
      content: '';
      position: absolute;
      width: $radio-input-height;
      height: $radio-input-height;
      border-radius: $border-radius-circle;
      transition: border-color $radio-transition-duration ease-out;
      @include themify($themes) {
        border: $radio-border-width solid themed('radio-border-color');
      }
    }

    &.checked,
    .focused {
      &::before {
        @include themify($themes) {
          border-color: themed('radio-color');
        }
      }

      &::after {
        content: '';
        position: absolute;
        width: $radio-focused-height;
        height: $radio-focused-height;
        left: $radio-focused-left;
        border-radius: $border-radius-circle;
        transition: background-color $radio-transition-duration ease-out;
        @include themify($themes) {
          background-color: themed('radio-color');
        }
      }
    }

    &:hover {
      &::before {
        @include themify($themes) {
          border-color: themed('radio-color');
        }
      }
    }
  }
}
