@import 'theme/themify';
@import 'theme/variables';

:global {
  .custom-react-datepicker {
    outline: 0;
    min-height: $form-input-min-height;
    border: none;
    box-shadow: none;
    font-size: $font-size-md;
    line-height: $line-height-sm;
    border-radius: $input-main-border-radius;
    padding: $input-main-padding;
    width: $content-full-width;
    transition: border-color, border-width,
      width $form-select-control-transition-duration ease-in-out;

    @include themify($themes) {
      color: themed('input-main-text-color');
      opacity: themed('input-main-text-opacity');
      background-color: themed('input-main-bg-color');
      border-bottom: $border-width-base solid themed('input-main-border-color');
    }

    &:focus {
      border-width: $border-width-bolder;
    }
  }

  .react-datepicker-wrapper {
    display: block;
    font-size: $font-size-md;
  }

  .react-datepicker {
    @include themify($themes) {
      border: 0.07rem solid themed('datepicker-main-border-color');
    }

    .react-datepicker__header {
      @include themify($themes) {
        background-color: themed('input-main-bg-color');
        border-bottom: 1px solid themed('datepicker-main-border-color');
      }
    }

    .react-datepicker__current-month {
      @include themify($themes) {
        color: themed('datepicker-text-color');
      }
    }

    .react-datepicker__day-name {
      @include themify($themes) {
        color: themed('datepicker-text-color');
      }
    }

    .react-datepicker__day {
      @include themify($themes) {
        color: themed('datepicker-text-color');
      }
    }

    .react-datepicker__day--selected {
      @include themify($themes) {
        color: themed('datepicker-selected-item-text-color');
      }
    }

    .react-datepicker__day--keyboard-selected {
      @include themify($themes) {
        color: themed('datepicker-selected-item-text-color');
      }
    }

    .react-datepicker__navigation {
      outline: 0;
    }

    .react-datepicker__day--disabled {
      @include themify($themes) {
        color: themed('muted-text-color');
      }
    }
  }
}
