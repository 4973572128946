.slides-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  .transparent-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    background-image: linear-gradient(transparent 40%, black 85%);
    z-index: 1;
  }

  .slideshow-container {
    width: 100%;
    height: 100%;
    display: flex;
    transition: transform 0.8s ease;

    .slide {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    top: 40%;
    width: auto;
    padding: 1rem;
    color: white !important;
    font-weight: bold;
    font-size: 1.4rem;
    transition: 0.6s ease;
    user-select: none;
    z-index: 1;
  }

  .prev {
    left: 0;
  }

  .next {
    right: 0;
  }

  .prev:hover,
  .next:hover {
    color: rgba(0, 0, 0, 0.8) !important;
    text-decoration: none;
  }

  .dot-container {
    width: 100%;
    position: absolute;
    bottom: 1%;
    text-align: center;
    z-index: 1;
    .dot {
      height: 0.55rem;
      width: 0.55rem;
      margin: 0 0.2em;
      background-color: #bbb;
      border-radius: 50%;
      display: inline-block;
      transition: background-color 0.6s ease;
    }

    .active {
      background-color: #717171;
    }
  }
}
