/* Regular */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Noto Sans'), local('NotoSans'),
    url('~assets/fonts/NotoSans-Regular.ttf') format('truetype');
}

/* Bold */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Noto Sans Bold'), local('NotoSans-Bold'),
    url('~assets/fonts/NotoSans-Bold.ttf') format('truetype');
}
