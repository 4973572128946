@import 'theme/variables';

.document {
  :global {
    span {
      &.spaced {
        display: inline-block;
        min-width: $document-spaced-text-min-width;
      }
    }
    ol {
      list-style: none;
      counter-reset: list;
      padding-left: $document-list-item-padding-left;

      li {
        margin-bottom: $form-element-container-margin-bottom;
        position: relative;

        &::before {
          counter-increment: list;
          position: absolute;
          left: $document-list-item-marker-gap;
          content: counter(list, lower-alpha) '.';
        }
      }

      &.parentheses {
        li {
          &::before {
            content: '(' counter(list, lower-alpha) ') ';
          }
        }
      }
    }
  }
}
