@import 'theme/themify';
@import 'theme/variables';

:local .form-select-container {
  min-height: $form-input-min-height;

  :global {
    .form-select__control {
      border: none;
      box-shadow: none;
      font-size: $font-size-md;
      line-height: $line-height-sm;
      border-radius: $input-main-border-radius;
      padding: $form-select-control-padding;
      transition: border-color, border-width,
        width $form-select-control-transition-duration ease-in-out;

      @include themify($themes) {
        color: themed('input-main-text-color');
        opacity: themed('input-main-text-opacity');
        background-color: themed('input-main-bg-color');
        border-bottom: $border-width-base solid
          themed('input-main-border-color');
      }

      &--is-focused {
        border-bottom-width: $border-width-bolder !important;
      }

      &--is-disabled {
        background-color: unset !important;

        @include themify($themes) {
          border-color: themed('input-main-read-only-border-color');
        }
      }
    }

    .form-select__placeholder {
      opacity: $form-select-placeholder-opacity;
      font-size: $font-size-md;
      line-height: $line-height-base;
    }

    .form-select__single-value {
      font-size: $font-size-md;
      line-height: $line-height-base;

      @include themify($themes) {
        color: themed('input-main-text-color');
      }
    }
  }
}
