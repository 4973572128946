@import 'theme/themify';
@import 'theme/variables';

:local .footer-main {
  @include themify($themes) {
    background-color: themed('footer-main-bg-color');
    box-shadow: 0 $footer-main-box-shadow-offset-y
      $footer-main-box-shadow-blur-radius themed('footer-main-shadow-color');
  }

  margin-top: $footer-main-margin-top;
  padding-top: $footer-main-padding-top;
  padding-bottom: $footer-main-padding-bottom;

  @mixin text-entry {
    font-weight: $font-weight-super-light;
    font-size: $footer-main-span-font-size;
    line-height: $footer-main-span-line-height;

    @include themify($themes) {
      color: themed('footer-main-text-color');
    }
  }

  span {
    @include text-entry;

    &::before {
      content: '\007C';
      text-decoration: none !important;
      margin: 0 $coupon-action-buttons-container-margin-left;
    }
  }

  a {
    @include text-entry;

    font-size: $side-nav-a-font-size;
  }
}
