@import 'theme/themify';
@import 'theme/variables';

.form-switch-container {
  margin-bottom: $form-element-container-margin-bottom;
}

:global {
  .react-switch-handle {
    height: $react-switch-handle-height !important;
    width: $react-switch-handle-height !important;
    @include themify($themes) {
      box-shadow: themed('switch-shadow-color');
    }
  }

  .react-switch-bg {
    height: $react-switch-bg-height !important;
    width: $react-switch-bg-width !important;
    margin-top: $react-switch-bg-margin-top !important;
    transform: translateX($react-switch-bg-translate-x) !important;
  }

  .checked-switch-bg {
    .react-switch-bg {
      @include themify($themes) {
        background-color: themed('switch-checked-bg-color') !important;
      }
    }
  }
}
