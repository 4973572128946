@import 'theme/themify';
@import 'theme/variables';

:local .main-input-container {
  position: relative;

  .main-input {
    position: relative;
    outline: 0;
    min-height: $form-input-min-height;
    width: $content-full-width;
    border: none;
    font-size: $font-size-md;
    line-height: $line-height-sm;
    border-radius: $input-main-border-radius;
    padding: $input-main-padding;
    transition: border-color, border-width,
      width $form-select-control-transition-duration ease-in-out;

    @include themify($themes) {
      color: themed('input-main-text-color');
      opacity: themed('input-main-text-opacity');
      background-color: themed('input-main-bg-color');
      border-bottom: $border-width-base solid themed('input-main-border-color');
    }

    &.checkbox-input {
      width: $checkbox-input-width;
      // margin: 0 auto; /* Center the checkbox horizontally */

    }

    &.hide-caret {
      caret-color: transparent;
    }

    &:focus {
      border-width: $border-width-bolder;
    }

    &.is-closed-select {
      background-image: url(./DropdownMark.svg);
      background-repeat: no-repeat;
      background-position: $input-dropdown-icon-bg-position;
      background-size: $input-icon-bg-size;
      padding-right: $input-main-with-dropdown-icon-padding-right;
    }

    &.is-clearable {
      padding-right: $input-main-with-icon-padding-right;
    }

    &.read-only {
      background-color: unset;

      @include themify($themes) {
        border-color: themed('input-main-read-only-border-color');
      }

      &:focus {
        border-width: $border-width-base;
      }
    }

    &.is-valid {
      background-image: url(./SignMark.svg);
      background-repeat: no-repeat;
      background-position: $input-icon-bg-position;
      background-size: $input-icon-bg-size;
      padding-right: $input-main-with-icon-padding-right;

      @include themify($themes) {
        border-color: themed('input-main-valid-border-color');
      }
    }

    &.is-invalid {
      background-image: url(./InvalidMark.svg);
      background-repeat: no-repeat;
      background-position: $input-icon-bg-position;
      background-size: $input-icon-bg-size;
      padding-right: $input-main-with-icon-padding-right;

      @include themify($themes) {
        border-color: themed('input-main-invalid-border-color');
      }
    }
  }

  .close-button-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: $input-main-padding-horizontal;
    display: flex;
    align-items: center;
  }
}
