@import 'theme/themify';
@import 'theme/variables';

:local {
  .app-container {
    display: flex;
    flex-direction: column;
    min-height: $content-full-height;

    a {
      @include themify($themes) {
        color: themed('app-container-link-text-color');
      }
    }

    p {
      &:global.text-muted {
        @include themify($themes) {
          color: themed('muted-text-color');
        }
      }
    }

    @include themify($themes) {
      color: themed('app-container-text-color');
      background-color: themed('app-container-bg-color');
    }

    :global {
      table {
        @include themify($themes) {
          color: themed('app-container-text-color');
          border-bottom: $border-width-base solid
            themed('table-header-bg-color');
        }

        th,
        td {
          padding: $table-cell-padding-vertical $table-cell-padding-horizontal;
        }

        th {
          letter-spacing: $letter-spacing-base;
        }

        &.table-striped {
          tbody tr:nth-of-type(odd) {
            background-color: unset;
          }

          tbody tr:nth-of-type(even) {
            @include themify($themes) {
              background-color: themed('table-row-accent-bg-color');
            }
          }

          &.striped-odd {
            tbody tr:nth-of-type(odd) {
              @include themify($themes) {
                background-color: themed('table-row-accent-bg-color');
              }
            }

            tbody tr:nth-of-type(even) {
              background-color: unset;
            }
          }
        }

        &.table {
          .thead-light {
            th {
              @include themify($themes) {
                background-color: themed('table-header-bg-color');
              }
            }
          }
        }
      }
    }

    &.light-bg {
      @include themify($themes) {
        background-color: themed('app-container-bg-color-light');
      }
    }

    .heading-row {
      margin-bottom: $heading-row-margin-bottom;
    }

    .side-nav {
      display: flex;
      flex-direction: column;

      a {
        margin-bottom: $side-nav-a-margin-bottom;
        padding: $side-nav-a-padding-y $side-nav-a-padding-x;
        font-weight: $font-weight-semi-bold;
        font-size: $side-nav-a-font-size;
        line-height: $header-line-height-base;
        letter-spacing: $letter-spacing-base;
        text-transform: uppercase;
        text-align: center;
        border-radius: $side-nav-a-border-radius;
        transition: box-shadow $form-select-control-transition-duration
          ease-in-out;

        @include themify($themes) {
          color: themed('app-container-text-color');
          border: 1px solid #d9d9d9;
        }

        &:hover {
          text-decoration: none;
          border: 1px solid #acacac;
        }

        &:global.active {
          box-shadow: inset #d9d9d9 0 0 20px -2px;
          border: 1px solid #acacac;
        }
      }
    }
  }
}
