@import 'theme/variables';
@import 'theme/themify';

// Manage points column
.add-points{
    color: rgb(222, 5, 67);
    font-weight: bold;
    cursor: pointer;
}

// Table

:global {
    .react-bootstrap-table-pagination {
        margin-right: 0 !important;
        margin-left: 0 !important;
        margin-top: 0.5rem;
    }
}

.table-container {

    max-height: 24rem;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 7px; /* Vertical scrollbar width */
        height: 7px; /* Horizontal scrollbar height */
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.5); /* Thumb color */
        border-radius: 3px; /* Rounded corners */
    }

}




.row {
    gap: 10px;
}

.btn-container {
    display: flex;
    background: #F1F1F1;
    border-radius: 50px;
    padding: 5px;
    gap: 5px;
    overflow: hidden;

    button {
        border: none;
        padding: 5px 10px;
        border-radius: 50px;
        font-weight: bold;
        font-size: 11px;
        color: black;

        &.active {
            // background: crimson;
            @include themify($themes) {
                background: themed('button-primary-bg-color');
            }
            color: white;
            transition: 0.4s;
        }
    }




}

.search {
    padding: 5px;
    background: #F1F1F1;
    border-radius: 50px;

    input {
        border: none;
        outline: none;
        background: #F1F1F1;
        border-radius: 50px;
        padding: 3px 2px;
        font-size: 12px;
        color: black;
        font-weight: 600;

        &:focus {
            outline: none; // Removes the default focus border
            box-shadow: none; // Removes any box shadow applied by the browser
        }

        &::placeholder {
            color: black;

        }
    }


}

.transaction-btn{
    justify-self: end;
    padding: 5px 10px;
    color: white;
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    @include themify($themes) {
        background: themed('button-primary-bg-color');
    }
    border-radius: 50px;

    a{
        color: white;
        text-decoration: none;

        &:visited{
            color: white;
        }
    }
}

// Points Modal

.managePointsModal{
    display: flex;
    flex-direction: column;
    gap: 20px;


    .styledSelect select {
        padding: 10px 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: #fff;
        color: #333;
        font-size: 13px;
        outline: none;
        cursor: pointer;
    }


    .styledSelect select:focus {
        border-color: #007BFF;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    }

    .styledSelect option {
        background-color: #fff;
        color: #333;
        font-size: 13px;
        padding: 5px;
    }


    input {
        border: none;
        outline: none;
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: #fff;
        color: #333;

        &:focus {
            outline: none;
            box-shadow: none;
            border-color: #007BFF;
            box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
        }

        &::placeholder {
            color: black;

        }
    }

}