@import 'theme/themify';
@import 'theme/variables';

:global {
  .chart-container {
    overflow: auto;
  }
}

:local {
  .chart {
    font-size: $chart-font-size;

    .dollars-bar {
      @include themify($themes) {
        fill: themed(dollars-chart-color);
      }
    }

    .points-bar {
      @include themify($themes) {
        fill: themed(points-chart-color);
      }
    }

    .amount-off-bar {
      @include themify($themes) {
        fill: themed(amount-off-chart-color);
      }
    }

    .points-back-bar {
      @include themify($themes) {
        fill: themed(points-back-chart-color);
      }
    }

    .tic-label {
      @include themify($themes) {
        fill: themed(chart-tick-label-color);
      }
    }

    .legend-label {
      line-height: $chart-legend-label-line-height;
      font-size: $chart-font-size;
      @include themify($themes) {
        color: themed(chart-legend-label-color);
      }
    }

    .legend-icon {
      height: $chart-legend-icon-height;
      width: $chart-legend-icon-height;

      rect {
        height: $chart-legend-icon-height;
        width: $chart-legend-icon-height;
      }
    }
  }
}
