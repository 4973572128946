@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';
@import 'theme/variables';

.coupon-action-modal-container {
  max-width: $coupon-action-modal-container-max-width;

  button {
    margin-top: $coupon-action-modal-button-margin-top;
  }

  @include media-breakpoint-down(sm) {
    button {
      margin-left: 0;
    }
  }
}
