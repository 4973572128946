@import 'theme/themify';
@import 'theme/variables';

.spinner-border-sm {
  width: $sm-spinner-width;
  height: $sm-spinner-width;
}

.spinner-border-md {
  width: $md-spinner-width;
  height: $md-spinner-width;
}

.spinner-border-lg {
  width: $lg-spinner-width;
  height: $lg-spinner-width;
}
