@import 'theme/themify';
@import 'theme/variables';

:local {
  .checkbox {
    display: flex;
    align-items: center;
    margin-bottom: $checkbox-margin-bottom;

    input {
      cursor: pointer;
      opacity: 0;
      position: absolute;
      width: $checkbox-input-width;
      height: $checkbox-input-width;
      z-index: 1;
    }

    svg {
      width: $checkbox-icon-width;
      height: $checkbox-icon-width;
      margin: 0 $checkbox-icon-margin-right 0 $checkbox-icon-margin-left;

      &.checkbox-outline {
        @include themify($themes) {
          fill: themed('checkbox-outline-color');
        }
      }

      &.checkbox-checked {
        @include themify($themes) {
          fill: themed('checkbox-checked-color');
        }
      }
    }
  }
}
