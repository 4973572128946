@import 'theme/variables';

.process-coupon-modal-container {
  max-width: $coupon-preview-modal-container-max-width;
}

.coupon-price-details {
  hr {
    margin: $coupon-price-details-hr-margin-top 0
      $coupon-price-details-hr-margin-top;
  }

  button {
    margin: $coupon-price-details-button-margin-top 0 0
      $coupon-price-details-button-margin-left;
  }
}

:global {
  .coupon-processing-spinner-container {
    margin-top: 28px;
    margin-bottom: 10px;
  }
}
