@import 'theme/themify';
@import 'theme/variables';

:local .feedback {
  display: block;
  margin-top: $invalid-feedback-margin-top;
  font-size: $font-size-sm;

  &.full-width {
    width: $content-full-width;
  }

  &.invalid {
    @include themify($themes) {
      color: themed('invalid-feedback-text-color');
    }
  }

  &.warn {
    @include themify($themes) {
      color: themed('warn-feedback-text-color');
    }
  }

  &.valid {
    @include themify($themes) {
      color: themed('valid-feedback-text-color');
    }
  }
}
