@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';
@import 'theme/themify';
@import 'theme/variables';

:global {
  .coupons-list-item-separator {
    margin: $coupons-list-item-separator-margin-top 0
      $coupons-list-item-separator-margin-bottom;
    @include themify($themes) {
      border-color: themed('coupons-list-item-separator-border-color');
    }
  }

  .coupon-list-animation-enter {
    opacity: $coupon-list-animation-start-opacity;
  }

  .coupon-list-animation-enter-active {
    opacity: $coupon-list-animation-finish-opacity;
    transition: opacity $coupon-list-animation-duration ease-in;
  }

  .coupon-list-animation-exit {
    opacity: $coupon-list-animation-finish-opacity;
  }

  .coupon-list-animation-exit-active {
    opacity: $coupon-list-animation-start-opacity;
    transition: opacity $coupon-list-animation-duration ease-in;
  }
}

.coupon-container {
  width: $coupon-container-default-width;
}

.coupon-action-buttons-container {
  width: $coupon-action-buttons-container-default-width;
  margin-left: $coupon-action-buttons-container-margin-left;

  .coupon-action-section {
    font-size: $coupon-action-section-font-size;
    margin-bottom: $coupon-action-section-margin-bottom;
    @include themify($themes) {
      color: themed(muted-text-color);
    }

    .usage-info-section-value {
      border: $coupon-template-usage-info-border-width solid;
      line-height: $line-height-sm;
      font-weight: $font-weight-bold;
      border-radius: $coupon-template-usage-info-border-radius;
      padding: $coupon-template-usage-info-section-value-padding-top
        $coupon-template-usage-info-section-value-padding-left;
      @include themify($themes) {
        border-color: themed('coupon-usage_info-border-color');
      }
    }

    .usage-info-section-text {
      padding-left: $mycoupons-template-usage-info-section-text-padding-left;
      line-height: $line-height-sm;
    }
  }
}

@include media-breakpoint-down(sm) {
  .coupon-container {
    width: $coupon-container-mobile-width;
  }

  .coupon-action-buttons-container {
    display: flex;
    width: $coupon-action-buttons-container-mobile-width;
    margin-left: 0;
    flex-wrap: wrap;

    .coupon-action-section {
      margin-bottom: 0;
      flex-basis: $coupon-action-section-flex-basis;
      margin-top: $coupon-action-section-margin-top;
    }
  }
}
