@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';
@import 'theme/themify';
@import 'theme/variables';

:local {
  .coupon-template-block {
    @include themify($themes) {
      background-color: themed('coupon-template-block-bg-color');
      color: themed('coupon-template-block-text-color');
    }

    &.published-coupon-block {
      @include themify($themes) {
        background: themed('coupon-template-block-bg-gradient-color');
      }
    }

    border-radius: $block-border-radius;
    @include themify($themes) {
      box-shadow: 0 $box-shadow-offset-base themed('block-shadow-color');
    }

    .coupon-details-block {
      height: $coupon-template-coupon-details-block-height;
      position: relative;
      overflow: hidden;
      border-radius: $block-border-radius $block-border-radius 0 0;
      .image-slider-container {
        position: absolute;
        inset: 0;
      }
    }

    .sponsored-block {
      border-radius: $coupon-sponsored-block-border-radius;
      @include themify($themes) {
        background-color: themed('coupon-sponsored-block-bg-color');
      }
    }
    .sponser-text-block {
      height: $coupon-sponsored-block-height;
      width: $coupon-sponsored-block-width;
      span {
        font-size: $font-size-sm;
        text-transform: uppercase;
      }
    }
    .icon-button-block {
      height: $coupon-template-icon-button-block-height;
      width: $coupon-template-icon-button-block-height;
      opacity: $coupon-template-icon-button-block-opacity;
      border-radius: $coupon-sponsored-block-border-radius;
      @include themify($themes) {
        background-color: themed('coupon-template-block-icon-background-color');
      }

      img {
        width: $coupon-template-icon-button-height;
      }

      span {
        display: block;
        color: black;
        font-size: $font-size-lg;
        font-weight: $font-weight-semi-bold;
      }
    }
    .top-left-icon-button {
      border-radius: 0.6rem 0 0.6rem 0;
    }

    .coupon-template-content {
      margin: $coupon-template-content-padding;
    }

    .title-section {
      font-size: $coupon-template-title-section-font-size;
      font-weight: $font-weight-bold;
      margin: 0;
    }

    .coupon-type-section {
      margin: 1rem 0 0;
      font-weight: $font-weight-bold;

      h3 {
        font-size: $coupon-template-amount-off-section-font-size;
      }

      .payment-type-section {
        font-size: 1.1rem;
        font-weight: $font-weight-normal;
      }
    }

    .description-section {
      &.bordered {
        @include themify($themes) {
          border: $border-width-base dashed
            themed('coupon-description-border-color');
        }
      }

      white-space: pre-wrap;
      min-height: $coupon-template-description-section-min-height;
      font-size: $coupon-template-description-section-font-size;
      margin-top: $coupon-template-description-section-margin-top;
      padding: $coupon-template-description-section-padding;
    }

    .usage-info-section {
      margin-top: $coupon-template-usage-info-section-margin-top;

      img {
        width: $coupon-template-usage-info-section-img-height;
        height: $coupon-template-usage-info-section-img-height;
      }
    }

    .usage-info-section-value {
      @include themify($themes) {
        border: $coupon-template-usage-info-border-width solid
          themed('coupon-template-border-color');
      }

      border-radius: $coupon-template-usage-info-border-radius;
      line-height: $line-height-sm;
      padding: $coupon-template-usage-info-section-value-padding-top
        $coupon-template-usage-info-section-value-padding-left;
      font-weight: $font-weight-bold;
      font-size: $coupon-template-usage-info-section-value-font-size;
    }

    .usage-info-section-text {
      // padding: 0 $coupon-template-usage-info-section-text-padding-left;
      padding-right: $coupon-template-usage-info-section-text-padding-left;
      line-height: $line-height-sm;
      font-size: $coupon-template-usage-info-section-text-font-size;
    }

    .business-info-section {
      padding: $coupon-template-content-padding;
      border-radius: $coupon-template-business-info-border-radius;

      @include themify($themes) {
        background-color: themed(
          'coupon-template-block-business-info-background-color'
        );
        color: themed('coupon-template-block-business-info-text-color');
      }

      .business-logo {
        height: $coupon-template-business-logo-img-height;
        width: $coupon-template-business-logo-img-height;
        border-radius: $coupon-template-business-info-img-border-radius;
        @include themify($themes) {
          box-shadow: 0 $box-shadow-offset-base themed('block-shadow-color');
        }
      }

      .company-name {
        font-size: $coupon-template-company-name-section-font-size;
      }

      .published-date {
        @include themify($themes) {
          color: themed('muted-text-color');
        }
      }

      .coupon-status-icon {
        position: relative;
        cursor: pointer;

        .tooltip-text {
          @include themify($themes) {
            background-color: themed('coupon-status-tooltip-background-color');
            box-shadow: themed('coupon-status-tooltip-box-shadow');
          }

          visibility: hidden;
          font-size: $coupon-status-tooltip-font-size;
          border-radius: $coupon-status-tooltip-border-radius;
          padding: $coupon-status-tooltip-padding;
          position: absolute;
          z-index: 1;
          left: $coupon-status-tooltip-left;
          width: max-content;
        }

        @include media-breakpoint-down(sm) {
          .tooltip-text {
            top: $coupon-status-tooltip-top-small-devices;
            left: auto;
          }
        }

        &:hover {
          .tooltip-text {
            visibility: visible;
          }
        }
      }
    }
  }
}
