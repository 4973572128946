@import 'theme/variables';
@import 'theme/themify';

.points-system-modal-container {
    max-width: $change-password-modal-container-max-width;

    .remaining{
        display: flex;
        justify-content: end;
        align-items: start;

        > span{
            @include themify($themes) {
                background: themed('button-primary-bg-color');
            }
            color: white;
            padding: 3px 4px;
            border-radius: 5px;
            font-weight: 600;
        }
    }
}

