@import 'theme/themify';
@import 'theme/variables';

.coupon-price-details-container {
  margin-bottom: 1rem;
  padding: 1rem 1.5rem 0 1.5rem;

  p {
    margin-bottom: $form-field-label-margin-bottom;
    font-size: $form-field-label-font-size;
    line-height: $form-field-label-line-height;

    @include themify($themes) {
      color: themed('form-field-label-text-color');
    }
  }

  p.price-info-text {
    color: black;
    font-size: 1rem;
    margin: 1.6rem 0;
    text-align: center;
    font-size: 0.78rem;

    .price-off-text {
      font-weight: bold;
      margin: 0 15px 0 10px;
      font-size: 1.1rem;
    }

    .final-price-info-text {
      color: #00c52b;
      font-weight: bold;
      margin-right: 10px;
      font-size: 1.5rem;
    }

    .tax-info-text {
      font-style: italic;
      font-size: $form-field-label-font-size;
    }
  }

  p.tax-rates-form-heading {
    text-transform: capitalize;
    margin-bottom: 0.5rem;
  }

  .tax-rates-grid-container {
    width: 87%;

    .left-grid-item {
      width: 33%;
      &:not(:nth-child(3)) {
        padding-right: $coupon-builder-grid-item-padding-right;
      }
    }
  }

  .coupon-price-input {
    min-height: 3rem;
    padding: $input-main-padding;
  }
}

.coupon-price-filed-groupd-details-container {
  p {
    font-size: 1rem;
  }

  .price {
    font-size: $coupon-price-details-price-font-size;
    line-height: $coupon-price-details-price-font-size;
    font-weight: bold;
  }
}

:local {
  .coupon-price-field-group-points-back-text {
    font-size: 0.8rem;
    text-align: center;
    font-style: italic;
  }
}
