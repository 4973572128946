@import 'theme/themify';
@import 'theme/variables';

:local {
  .img-container {
    flex: 1;
    cursor: pointer;

    input {
      display: none;
    }

    img {
      margin-bottom: $img-container-img-margin-bottom;
    }

    &.text-dark {
      @include themify($themes) {
        color: themed('muted-text-color');
      }
    }

    .img-placeholder {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-bottom: $img-placeholder-margin-bottom;

      @include themify($themes) {
        background-color: themed('form-image-field-placeholder-bg-color');
        border: $border-width-base dashed
          themed('form-image-field-placeholder-border-color');
      }
    }

    &.mt {
      margin-top: $block-margin-x;
    }

    .rounded {
      border-radius: $border-radius-circle;
    }

    .drag-active {
      @include themify($themes) {
        border: $border-width-bolder dashed
          themed('form-field-label-text-color');
      }
    }
  }
}
