@import 'theme/themify';
@import 'theme/variables';

:local .main-textarea {
  outline: 0;
  resize: none;
  min-height: $form-textarea-min-height;
  width: $content-full-width;
  border: none;
  font-size: $font-size-md;
  line-height: $line-height-sm;
  border-radius: $input-main-border-radius;
  padding: $input-main-padding;
  transition: border-color, border-width,
    width $form-select-control-transition-duration ease-in-out;

  @include themify($themes) {
    color: themed('input-main-text-color');
    opacity: themed('input-main-text-opacity');
    background-color: themed('input-main-bg-color');
    border-bottom: $border-width-base solid themed('input-main-border-color');
  }

  &:focus {
    border-width: $border-width-bolder;
  }

  &.read-only {
    background-color: unset;

    @include themify($themes) {
      border-color: themed('input-main-read-only-border-color');
    }

    &:focus {
      border-width: $border-width-base;
    }
  }
}
