@import 'theme/themify';
@import 'theme/variables';

:local {
    .table-container {

        max-height: 26rem;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 7px;
            /* Vertical scrollbar width */
            height: 7px;
            /* Horizontal scrollbar height */
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.5);
            /* Thumb color */
            border-radius: 3px;
            /* Rounded corners */
        }

    }

    .row {
        gap: 10px;
    }


    .first_name{
        text-transform: uppercase;
    }

    .status{
        text-transform: capitalize;
        &.ignored-request-status {
            cursor: pointer;
            @include themify($themes) {
                color: themed('points-requests-list-item-title-color');
            }
        }
    }

    .sticky-header {
        position: sticky;
        top: 0;
        background-color: #F5F9FE;
        z-index: 2;
    }

}