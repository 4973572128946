@import 'theme/themify';
@import 'theme/variables';

:local {
  .modal {
    border-radius: $block-border-radius;
    padding: $block-padding;
    display: inline-flex;
    min-width: $modal-min-width;

    .modal-container {
      width: $full-width;

      .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: $modal-header-margin-bottom;
        gap: $modal-header-gap;

        h3 {
          margin-top: 0;
        }

        &.hidden {
          display: none;
        }
      }
    }

    @include themify($themes) {
      background-color: themed('modal-bg-color');
    }
  }

  .centering-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: $full-height;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-x: auto;
    padding: $modal-overlay-padding;
    z-index: 10;

    @include themify($themes) {
      background-color: themed('modal-overlay-bg-color');
    }
  }
}
