@import 'theme/variables';
@import 'theme/themify';

:local {

    .table-container {

        max-height: 26rem;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 7px;
            /* Vertical scrollbar width */
            height: 7px;
            /* Horizontal scrollbar height */
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.5);
            /* Thumb color */
            border-radius: 3px;
            /* Rounded corners */
        }

    }

    .row {
        gap: 10px;
    }


    .search {
        padding: 5px;
        background: #F1F1F1;
        border-radius: 50px;

        input {
            border: none;
            outline: none;
            background: #F1F1F1;
            border-radius: 50px;
            padding: 3px 2px;
            font-size: 12px;
            color: black;
            font-weight: 600;

            &:focus {
                outline: none; // Removes the default focus border
                box-shadow: none; // Removes any box shadow applied by the browser
            }

            &::placeholder {
                color: black;

            }
        }


    }

    .positive {
        color: rgb(135, 217, 11);
        font-weight: bold;
    }

    .negative {
        color: crimson;
        font-weight: bold;
    }

    .sticky-header {
        position: sticky;
        top: 0;
        background-color: #F5F9FE;
        z-index: 2;
    }
}