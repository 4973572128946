@import 'theme/themify';
@import 'theme/variables';

:local .block {
  border-radius: $block-border-radius;
  margin-bottom: $block-margin-x;
  padding: $block-padding;

  @include themify($themes) {
    background-color: themed('block-bg-color');
    box-shadow: 0 $box-shadow-offset-base themed('block-shadow-color');
  }

  &.fixed-min-height {
    min-height: $block-fixed-min-height;
  }

  :global {
    b {
      &.accent {
        font-size: $b-accent-font-size;
        line-height: $line-height-lg;
      }
    }

    .flex-basis-0 {
      flex-basis: 0%;
    }

    .flex-basis-50 {
      flex-basis: 50%;
    }

    .split-with-accent {
      margin-top: $split-with-accent-gap-margin;
      margin-right: $split-with-accent-gap-margin;
    }
  }
}
