@import 'theme/themify';
@import 'theme/variables';

:global {
  .navbar {
    padding-top: $navbar-content-padding-top;
    padding-bottom: $navbar-content-padding-bottom;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    @include themify($themes) {
      background-image: linear-gradient(to right, #f00, #900);
      // background: linear-gradient(to right, #d11d3c 5%, #de2668 50%, #f83d5a 88%);
      color: themed('header-text-color');
    }

    .container {
      display: initial;
    }

    .navbar-content {
      margin-top: $navbar-content-margin-top;
      margin-bottom: $navbar-content-margin-bottom;
      padding-left: $navbar-content-padding-left;

      @include themify($themes) {
        border-left: $border-width-base solid themed('header-text-color');
      }

      .heading {
        font-size: $font-size-lg;
        font-weight: $font-weight-ultra-bold;
        letter-spacing: $navbar-heading-space-lettering;
        line-height: $header-line-height-base;
      }

      .points-block {
        display: flex;
        flex-direction: column;
        text-align: end;
        margin-right: $navbar-points-block-margin-right;

        strong {
          font-size: $header-points-font-size;
          font-weight: $font-weight-semi-bold;
          line-height: $header-line-height-base;
        }

        span {
          font-size: $font-size-xs;
          text-transform: uppercase;

          @include themify($themes) {
            color: themed('header-points-label-text-color');
          }
        }
      }
    }

    .navbar-brand {
      padding: 0;
    }
  }
}
