@import 'theme/themify';
@import 'theme/variables';

:local .close-icon-wrapper {
  svg {
    path {
      @include themify($themes) {
        fill: themed('close-icon-color');
      }
    }

    &:hover {
      cursor: pointer;

      path {
        @include themify($themes) {
          fill: themed('close-icon-active-color');
        }
      }
    }
  }
}
