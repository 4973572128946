@import 'theme/themify';
@import 'theme/variables';

:global {
  .react-bootstrap-table table {
    &.custom-react-bootstrap-table {
      table-layout: auto;

      thead tr {
        font-weight: $font-weight-semi-bold;
        @include themify($themes) {
          background: themed('custom-react-bootstrap-table-thead-background');
        }
      }

      th {
        outline: none;
      }
    }
  }

  .used-coupon-title {
    cursor: pointer;
    @include themify($themes) {
      color: themed('used-coupons-list-item-title-color');
    }
  }
}
