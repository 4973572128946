@import 'theme/variables';
@import 'theme/themify';

p.field_status_text {
  font-size: 0.9rem;
  @include themify($themes) {
    color: themed('input-status-text-color');
  }
}
.coupon-type-value-container {
  width: $coupon-builder-coupon-type-value-container-width;
}

.coupon-price-container {
  width: $coupon-builder-coupon-price-container-width;
}

.tax-rates-grid-container {
  width: $coupon-builder-tax-rates-grid-container-width;

  .left-grid-item {
    width: $coupon-builder-grid-item-width;
    padding-right: $coupon-builder-grid-item-padding-right;
  }

  .right-grid-item {
    width: $coupon-builder-grid-item-width;
    padding-left: $coupon-builder-grid-item-padding-right;
  }
}
