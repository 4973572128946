@import 'theme/themify';
@import 'theme/variables';

:local {
  .marketer-info-container {
    margin-top: $marketer-info-container-margin-top;
  }

  .marketer-icon {
    width: $marketer-icon-with;
    height: $marketer-icon-with;
    @include themify($themes) {
      fill: themed('marketer-icon-fill-color');
    }
  }

  .marketer-role {
    font-size: $marketer-role-font-size;
  }
}
