@import 'theme/themify';
@import 'theme/variables';

:global {
  .account-menu {
    svg {
      @include themify($themes) {
        fill: themed('header-text-color');
      }
    }

    .account-logo {
      width: $marketer-icon-with;
      height: $marketer-icon-with;
      padding: $account-logo-padding;
      border-radius: $border-radius-circle;

      @include themify($themes) {
        background-color: themed('app-container-bg-color-light');
      }
    }

    .btn-link {
      padding: 0;
    }

    .dropdown-menu {
      top: $account-menu-dropdown-top;
      right: $account-menu-dropdown-right;
      border-radius: $block-border-radius;

      @include themify($themes) {
        background-color: themed('header-account-menu-bg-color');
      }

      .dropdown-header {
        margin-bottom: $dropdown-header-margin-bottom;

        @include themify($themes) {
          color: themed('header-text-color');
        }

        strong {
          font-size: $dropdown-header-strong-font-size;
          font-weight: $font-weight-semi-bold;
          letter-spacing: $space-lettering-custom;
          margin-right: $dropdown-header-strong-margin-right;
        }
      }

      .dropdown-divider {
        opacity: $divider-opacity;
      }

      .dropdown-item {
        @include themify($themes) {
          color: themed('header-text-color');
        }

        svg {
          margin-right: $dropdown-header-strong-margin-right;
        }

        &:hover,
        :focus {
          @include themify($themes) {
            color: themed('header-account-menu-hover-text-color');
          }

          @include themify($themes) {
            background-color: themed('header-account-menu-hover-bg-color');
          }

          svg {
            @include themify($themes) {
              fill: themed('header-account-menu-hover-text-color');
            }
          }
        }
      }
    }
  }
}
