@import 'theme/themify';

:local {
  .clickable-text {
    cursor: pointer;
    width: max-content;
  }

  .dollars-colored-text {
    @include themify($themes) {
      color: themed(dollars-chart-color);
    }
  }
  .points-colored-text {
    @include themify($themes) {
      color: themed(points-chart-color);
    }
  }
}
