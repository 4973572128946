:local {
    .buttonGroup {
      display: flex;
      gap: 1rem;
      margin-bottom: 3rem;
    }

    .editButton,
    .cancelButton {
      background-color: #007bff;
      color: #fff;
      border: none;
      padding: 10px 20px;
      border-radius: 4px;
      cursor: pointer;
      font-size: 16px;
    }

    .cancelButton {
      background-color: #6c757d;
    }

    .editButton:hover {
      background-color: #0056b3;
    }

    .cancelButton:hover {
      background-color: #5a6268;
    }

    form{
        width: 100%;
        .fields{
            margin-top: 3rem;

        }
    }

  }