@import 'theme/variables';
@import 'theme/themify';

:local {
  .price {
    font-size: $coupon-price-details-price-font-size;
    line-height: $coupon-price-details-price-font-size;
    font-weight: bold;
  }

  .price-related-value {
    font-size: $coupon-price-details-price-related-value-font-size;
    margin-bottom: 0;
    @include themify($themes) {
      color: themed('muted-text-color');
    }
  }
}
