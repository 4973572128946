@import 'theme/variables';
@import 'theme/themify';

:local {
  .select-container {
    position: relative;

    .dropdown-container {
      overflow: hidden;
      position: absolute;
      top: $form-input-min-height;
      left: 0;
      right: 0;
      z-index: 2;

      @include themify($themes) {
        box-shadow: 0 $box-shadow-offset-y-down
          $form-select-dropdown-container-blur-radius
          themed('block-shadow-color');
      }
    }

    .options-list {
      position: relative;
      max-height: $form-custom-select-option-list-max-height;
      overflow-y: auto;

      @include themify($themes) {
        background-color: themed('app-container-bg-color');
      }

      .option {
        cursor: pointer;
        padding: $form-custom-select-menu-option-padding;

        &:hover,
        &.selected {
          @include themify($themes) {
            background-color: themed('form-select-selected-bg-color');
          }
        }

        &.active {
          opacity: $disabled-opacity;
        }
      }
    }
  }
}
