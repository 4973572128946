$content-full-height: 100%;
$content-full-width: 100%;

// Font family
$font-family-base: 'Noto Sans';

// Font size
$font-size: 1rem;
$font-size-lg: $font-size + 0.57rem;
$font-size-md: $font-size + 0.14rem;
$font-size-sm: $font-size - 0.2rem;
$font-size-xs: $font-size - 0.43rem;
$font-size-base: $font-size;

$h2-font-size: $font-size-base + 1.6rem;
$h3-font-size: $font-size-base + 0.7rem;
$h4-font-size: $font-size-base + 0.5rem;
$h5-font-size: $font-size-base + 0.3rem;
$h6-font-size: $font-size-base + 0.1rem;

$p-font-size: $font-size-base + 0.08rem;
$header-points-font-size: $font-size-base + 0.42rem;
$form-field-label-font-size: $font-size-base - 0.22rem;
$footer-main-span-font-size: $font-size-base - 0.07rem;
$dropdown-header-strong-font-size: $font-size-base + 0.07rem;
$side-nav-a-font-size: $font-size-base - 0.14rem;
$dashboard-block-value-font-size: $font-size-base + 1.5rem;
$b-accent-font-size: $font-size + $font-size-lg;

$coupon-template-title-section-font-size: $font-size-base + 1.4rem;
$coupon-template-amount-off-section-font-size: $font-size-base + 0.5rem;
$coupon-template-description-section-font-size: $font-size-base + 0.1rem;
$coupon-template-company-name-section-font-size: $font-size-base + 0.2rem;
$coupon-template-usage-info-section-value-font-size: $font-size-base + 0.2rem;
$coupon-template-usage-info-section-text-font-size: $font-size-base + 0.1rem;
$chart-font-size: 0.72rem;

$coupon-price-details-price-font-size: $font-size-base + 1rem;
$coupon-price-details-price-related-value-font-size: $font-size-base - 0.05rem;
$coupon-action-section-font-size: $font-size-base - 0.07rem;
$membership-plan-price-font-size: $font-size-base + 2.14rem;
$membership-plan-benefits-separator-font-size: $font-size-base + 0.21rem;
$membership-info-learn-more-link-font-size: $font-size-base + 0.07rem;
$custom-notification-badge-font-size: $font-size-base - 0.07rem;
$coupon-status-tooltip-font-size: $font-size-base - 0.07rem;
$marketer-role-font-size: $font-size-base - 0.14rem;

// Line height
$line-height: 1.36rem;
$line-height-lg: $line-height + 2.14rem;
$line-height-md: $line-height + 0.64rem;
$line-height-sm: $line-height + 0.06rem;
$line-height-base: $line-height;

$h2-line-height: $line-height-lg;
$h3-line-height: $line-height-md;
$h4-line-height: $line-height-md;
$h5-line-height: $line-height-lg;

$p-line-height: $line-height-base + 0.21rem;

$btn-primary-line-height: $line-height-base - 0.36rem;
$btn-lg-line-height: $line-height-base + 0.27rem;
$header-line-height-base: $line-height + 0.35rem;
$form-field-label-line-height: $line-height-base - 0.29rem;
$footer-main-span-line-height: $line-height-base - 0.08rem;
$chart-legend-label-line-height: $line-height-base - 0.36rem;
$membership-plan-benefits-separator: $line-height-base - 1rem;
$custom-notification-badge-line-height: $line-height-base - 0.33rem;

// Font style
$font-style-base: normal;

// Font weight
$font-weight-super-light: 300;
$font-weight-normal: normal;
$font-weight-semi-bold: 600;
$font-weight-bold: bold;
$font-weight-ultra-bold: 800;
$font-weight-base: $font-weight-normal;

$h2-font-weight: $font-weight-bold;
$h3-font-weight: $font-weight-semi-bold;
$h4-font-weight: $font-weight-semi-bold;
$h5-font-weight: $font-weight-semi-bold;

// Space lettering
$space-lettering-custom: 0.0015rem;
$navbar-heading-space-lettering: 0.01rem;

// Letter spacing
$letter-spacing-base: 0.01rem;

// Border
$border-radius-circle: 50%;
$block-border-radius: 0.6rem;
$btn-primary-border-radius: 2rem;
$side-nav-a-border-radius: 0.5rem;
$input-corner-border-radius: 0.28rem;
$input-main-border-radius: $input-corner-border-radius
  $input-corner-border-radius 0 0;

$border-width: 0.07rem;
$border-width-bolder: $border-width * 2;
$border-width-base: $border-width;
$coupon-template-usage-info-border-width: $border-width + 0.04;

$coupon-template-usage-info-border-radius: 1rem;
$coupon-template-business-info-border-radius: 0 0 0.6rem 0.6rem;
$coupon-template-business-info-img-border-radius: 2rem;
$radio-border-width: 0.15rem;
$coupon-sponsored-block-border-radius: 0 0.6rem 0 0.6rem;
$membership-plan-title-section-border-radius: 0.6rem 0.6rem 0 0;
$coupon-status-tooltip-border-radius: 0.28rem;

// Margin
$block-margin-x: 1.43rem;
$checkable-margin-x: 0.64rem;
$checkable-margin-y: 0.28rem;
$button-margin: 0.42rem;
$form-field-label-margin-bottom: 0.21rem;
$form-element-container-margin-bottom: 1.14rem;
$img-container-img-margin-bottom: 0.71rem;
$img-placeholder-margin-bottom: 0.71rem;
$invalid-feedback-margin-top: 0.25rem;
$subform-block-margin: 2rem 0 1.92rem 0;
$subform-block-large-margin-x: 5rem;
$only-form-page-wrapper-margin-bottom: 8.21rem;
$only-form-login-page-wrapper-margin-bottom: 3.85rem;
$graphics-container-margin-bottom: 1.57rem;
$dropdown-header-margin-bottom: 0.36rem;
$dropdown-header-strong-margin-right: 1rem;
$navbar-content-margin-top: 0.71rem;
$navbar-content-margin-bottom: 0.64rem;
$navbar-points-block-margin-right: 0.92rem;
$heading-row-margin-bottom: 1.78rem;
$side-nav-a-margin-bottom: 0.71rem;
$footer-main-margin-top: 2.14rem;
$landing-block-margin-top: 6rem;
$coupon-template-description-section-margin-top: 1.45rem;
$coupon-template-usage-info-section-margin-top: 1.45rem;
$coupon-template-total-price-section-margin-top: 2.2rem;
$dashboard-block-value-margin-bottom: 0.3rem;
$checkbox-margin-bottom: 1rem;
$checkbox-icon-margin-right: 0.78rem;
$checkbox-icon-margin-left: -0.21rem;
$coupon-price-details-hr-margin-top: 0.65rem;
$coupon-price-details-button-margin-top: 1.9rem;
$coupon-price-details-button-margin-left: 1.3rem;
$split-with-accent-gap-margin: 1.4rem;
$app-wrapper-heading-row-margin-top: 0.8rem;
$radio-margin-bottom: 1.2rem;
$radio-margin-right: 2rem;
$radio-label-margin-left: 0.5rem;
$radio-label-margin-bottom: 0;
$react-switch-bg-margin-top: 0.28rem;
$coupon-action-section-margin-bottom: 1.3rem;
$coupon-action-section-margin-top: 1rem;
$coupons-list-item-separator-margin-top: 2.14rem;
$coupons-list-item-separator-margin-bottom: 2rem;
$coupon-action-buttons-container-margin-left: 1rem;
$membership-plans-section-margin-top: 2.5rem;
$membership-plan-content-section-margin-bottom: 4.76rem;
$membership-plan-benefits-separator-margin-top: 1.14rem;
$membership-plan-benefits-separator-margin-bottom: 1rem;
$choose-membership-plan-btn-container-margin-bottom: 1.5rem;
$coupon-status-tooltip-left: 2.9rem;
$coupon-status-tooltip-top-small-devices: -2.5rem;
$marketer-info-container-margin-top: 0.6rem;
$delete-coupon-button-margin-top: 1.3rem;
$coupon-action-modal-button-margin-top: 1.3rem;

// Padding
$block-padding: 1.3rem;
$btn-primary-padding-x: 0.85rem;
$btn-primary-padding-y: 1.42rem;
$btn-lg-padding-x: 1.07rem;
$btn-lg-padding-y: 1.92rem;
$side-nav-a-padding-y: 1rem;
$side-nav-a-padding-x: 0.5rem;
$form-select-control-padding: 0.71rem 0.42rem 0.85rem 0.57rem;
$input-main-padding-horizontal: 1.28rem;
$input-main-padding: 0.92rem $input-main-padding-horizontal 0.85rem
  $input-main-padding-horizontal;
$input-main-with-icon-padding-right: 2.57rem;
$input-main-with-dropdown-icon-padding-right: 3rem;
$footer-main-padding-top: 1.21rem;
$footer-main-padding-bottom: 0.92rem;
$table-cell-padding-vertical: 0.92rem;
$table-cell-padding-horizontal: 1.5rem;
$modal-overlay-padding: 5%;
$navbar-content-padding-top: 0.64rem;
$navbar-content-padding-bottom: 0.5rem;
$navbar-content-padding-left: 1.42rem;
$modal-padding: 1.78rem;
$coupon-template-content-padding: 1.45rem;
$coupon-template-amount-off-section-padding-top: 1rem;
$coupon-template-usage-info-section-padding-top: 1.3rem;
$coupon-template-usage-info-section-value-padding-top: 0.25rem;
$coupon-template-usage-info-section-value-padding-left: 1.1rem;
$coupon-template-usage-info-section-text-padding-left: 0.8rem;
$mycoupons-template-usage-info-section-text-padding-left: 0.6rem;
$coupon-template-description-section-padding: 0.2rem;
$coupon-builder-grid-item-padding-right: 0.6rem;
$coupon-preview-block-padding-bottom: 1.9rem;
$account-logo-padding: 1rem / 7;
$radio-focused-left: 0.36rem;
$form-custom-select-menu-option-padding: 1rem 1.28rem;
$membership-plan-title-section-padding: 1.45rem;
$membership-plan-content-section-padding: 1.45rem;
$coupon-status-tooltip-padding: 0.72rem 1rem;
$document-list-item-padding-left: 3.9rem;

// Box shadow
$box-shadow-spread-radius-base: 0.2rem;
$box-shadow-offset-base: 0.14rem;
$box-shadow-offset-y-down: $box-shadow-offset-base + 0.4rem;

$block-box-shadow-offset: $box-shadow-offset-base $box-shadow-offset-base;
$btn-primary-box-shadow-offset-y: $box-shadow-offset-base * 2;
$btn-primary-box-shadow-blur-radius: 0.71rem;
$form-select-dropdown-container-blur-radius: 1.16rem;
$footer-main-box-shadow-offset-y: -0.07rem;
$footer-main-box-shadow-blur-radius: 0.57rem;
$modal-header-margin-bottom: 1.5rem;
$side-nav-active-a-offset: 0.28rem 1.14rem;
$coupon-action-btn-box-shadow-offset: 0 0 0.36rem;

// Opacity
$disabled-opacity: 0.5;
$divider-opacity: 0.2;
$coupon-list-animation-start-opacity: 0.01;
$coupon-list-animation-finish-opacity: 1;

// Width
$full-width: 100%;
$btn-primary-min-width: 10rem;
$btn-lg-min-width: 14.28rem;
$modal-min-width: 20%;
$sm-spinner-width: 1.5rem;
$md-spinner-width: 2rem;
$lg-spinner-width: 5rem;
$change-password-modal-container-max-width: 26.42rem;
$dashboard-simple-info-block-value-min-width: 4.45rem;
$checkbox-input-width: 2rem;
$checkbox-icon-width: 1.7rem;
$coupon-builder-coupon-type-value-container-width: 70%;
$coupon-builder-coupon-price-container-width: 92%;
$coupon-builder-tax-rates-grid-container-width: 80%;
$coupon-builder-grid-item-width: 50%;
$map-container-for-modal-width: 60vw;
$marketer-icon-with: (1rem / 7) * 20;
$statistic-interval-form-container-min-width: 14.64rem;
$coupon-preview-modal-container-max-width: 29rem;
$radio-input-height: 1.43rem;
$radio-focused-height: 0.71rem;
$react-switch-bg-width: 2.43rem;
$coupon-sponsored-block-width: 7.14rem;
$coupon-container-default-width: 77%;
$coupon-container-mobile-width: 100%;
$coupon-action-buttons-container-default-width: 20%;
$coupon-action-buttons-container-mobile-width: 100%;
$coupon-action-modal-container-max-width: 40rem;
$document-spaced-text-min-width: 3.57rem;

// Height
$full-height: 100%;
$form-input-min-height: 3.85rem;
$sm-block-min-height: 10rem;
$coupon-template-description-section-min-height: 5rem;
$coupon-template-business-logo-img-height: 3.3rem;
$coupon-template-usage-info-section-img-height: 2.1rem;
$coupon-template-coupon-details-block-height: 21rem;
$coupon-template-icon-button-block-height: 3rem;
$coupon-template-icon-button-height: 1.8rem;
$form-textarea-min-height: 7.5rem;
$map-container-height: 19.28rem;
$map-container-md-height: 14.28rem;
$map-container-for-modal-height: 58vh;
$map-container-for-modal-sm-height: 25vh;
$block-fixed-min-height: 13.58rem;
$chart-legend-icon-height: 1rem;
$form-custom-select-option-list-max-height: 28rem;
$react-switch-handle-height: 1.42rem;
$react-switch-bg-height: 1rem;
$coupon-sponsored-block-height: 1.7rem;
$landing-block-list-min-height: 21rem;

// Transition
$form-select-control-transition-duration: 0.05s;
$radio-transition-duration: 0.1s;
$coupon-list-animation-duration: 0.2s;

// Opacity
$form-select-placeholder-opacity: 0.6;
$radio-label-opacity: 0.9;
$coupon-template-icon-button-block-opacity: 0.8;

// Background
$input-icon-bg-position: right 0.56rem center;
$input-dropdown-icon-bg-position: right 1.28rem center;
$input-icon-bg-size: 1.125rem 1.125rem;

// Position
$account-menu-dropdown-top: -1.13rem;
$account-menu-dropdown-right: -1.5rem;
$document-list-item-marker-gap: -2rem;

// Translate
$react-switch-bg-translate-x: 0.43rem;

// Flex basis
$coupon-action-section-flex-basis: 50%;

// Gap
$modal-header-gap: 3rem;